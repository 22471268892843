@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700|Shojumaru:400&display=swap');

$mdc-typography-font-family: 'Source Sans Pro', sans-serif;;

@import "~@material/typography/mdc-typography";
@import "~@material/elevation/mdc-elevation";
@import "~@material/fab/mdc-fab";
@import "~@material/list/mdc-list";

body {
  background-color: #f5f5f5;
  color: #303030;
  margin: 0;
}

a {
  text-decoration: none;
}

.layout {
  margin-bottom: 53px;
}

.page-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  margin-left: 20px;
}

//login page
.login-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ninja-animation {
  width: 100%;
}

.logo {
  font-family: 'Shojumaru', cursive;
}

.socials {
  width: 220px;
  margin-top: 10px;
}

.social-button {
  display: flex;
  align-items: center;
  height: 40px;
  width: 200px;
  background: white;
  color: #737373;
  border-radius: 5px;
  border-width: 0;
  margin: 10px;
  padding: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

@mixin social-button__icon($icon) {
  background: url("./assets/images/#{$icon}.svg");
  vertical-align: middle;
  margin: 8px 0 8px 16px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}

.social-button__text {
  margin: 0 12px;
  font-size: 14px;
  font-family: 'Roboto', arial, sans-serif;
  text-decoration: none;
}

.facebook-button__icon {
  @include social-button__icon("facebook");
}

.google-button__icon {
  @include social-button__icon("google");
}

//header for course page
.header {
  background-color: #7c98ca;
  color: white;
  padding: 50px 10px 10px 30px;
  box-sizing: border-box;

  .title {
    font-weight: 700;
    margin-bottom: 3px;
  }

  .subtitle {
    margin: 3px 0;
  }
}

//session list in course page
.mdc-list-item {
  @include mdc-list-item-graphic-fill-color(rgba(127, 155, 207, 0.2));
  @include mdc-list-item-graphic-ink-color(#303030);
  @include mdc-list-item-meta-ink-color(rgba(124, 152, 202, 0.8));
}

.mdc-list-item--disabled {
  @include mdc-list-item-graphic-fill-color(rgba(201, 201, 201, 0.2));
  @include mdc-list-item-graphic-ink-color(#616263);
  @include mdc-list-item-meta-ink-color(#989898);
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  border-radius: 3px;
}

.mdc-list-item__graphic.material-icons {
  font-size: 18px;
}

//library page
.courses {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.course {
  margin: 8px;
  flex: 1 1 40%;

  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 3px;
  }

  h2 {
    margin: 0 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    color: #303030;
  }

  p {
    margin: 0 10px;
    font-size: 11px;
    line-height: 1.5;
    color: #303030;
  }
}

//back button
.mdc-fab {
  @include mdc-fab-accessible(white);
  position:fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

//navigation
.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 8px 0;
  margin: 0;
  background-color: #475c77;
  list-style: none;

  .tab a {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.8rem;
    color: #afb4b7;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .tab .active {
    color: #e5e9ee;
  }
}
